import Http from "@/services/Http";
export default {
  getParentDiscussions(data) {
    return Http().post("nest/discussion/list", data);
  },
  getDiscussionDetails(data) {
    return Http().post("nest/discussion/details", data);
  },
  saveComment(data) {
    return Http().post("nest/discussion/save/comment", data);
  },
  saveDiscussion(data) {
    return Http().post("nest/discussion/create", data);
  }
};
